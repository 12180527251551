import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../../src/assets/img/rank/first.png';
import _imports_1 from '../../../src/assets/img/rank/second.png';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-03ecdab8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "detail"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  class: "top"
};
var _hoisted_4 = {
  class: "title"
};
var _hoisted_5 = {
  class: "rule"
};
var _hoisted_6 = {
  key: 0
};
var _hoisted_7 = {
  key: 1
};
var _hoisted_8 = {
  key: 2
};
var _hoisted_9 = {
  style: {
    "margin-right": "40px"
  }
};
var _hoisted_10 = {
  class: "bottom",
  style: {
    "margin-top": "15px"
  }
};
var _hoisted_11 = {
  key: 0,
  src: _imports_0,
  style: {
    "width": "25px",
    "height": "30px",
    "vertical-align": "middle"
  }
};
var _hoisted_12 = {
  key: 1,
  src: _imports_1,
  style: {
    "width": "25px",
    "height": "30px",
    "vertical-align": "middle"
  }
};
var _hoisted_13 = {
  key: 2,
  src: _imports_1,
  style: {
    "width": "25px",
    "height": "30px",
    "vertical-align": "middle"
  }
};
var _hoisted_14 = {
  key: 3
};
var _hoisted_15 = {
  class: "item-name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "投票排行",
    back: true
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", null, _toDisplayString(_ctx.list.rank[0].activityName), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", null, [_createElementVNode("span", null, "投票规则: 投票活动期间每人" + _toDisplayString(_ctx.detail.detailContent.activity.voteCycle == 1 ? "只投一次" : "每天都可以投票") + ",", 1), _ctx.detail.detailContent.activity.voteCycle == 2 ? (_openBlock(), _createElementBlock("span", _hoisted_6, "每天都可投" + _toDisplayString(_ctx.detail.detailContent.activity.dayNums) + "票,", 1)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_ctx.detail.detailContent.activity.choiceType == 1 ? "只能投给其中一个投票项" : "可以投给多个投票项") + ",", 1), _ctx.detail.detailContent.activity.optionMaxNums ? (_openBlock(), _createElementBlock("span", _hoisted_7, "每个投票项最多可投" + _toDisplayString(_ctx.detail.detailContent.activity.optionMaxNums) + "票,", 1)) : _createCommentVNode("", true), _ctx.detail.detailContent.activity.maxNums ? (_openBlock(), _createElementBlock("span", _hoisted_8, "最多可投" + _toDisplayString(_ctx.detail.detailContent.activity.maxNums) + "票", 1)) : _createCommentVNode("", true)]), _createElementVNode("div", null, [_createElementVNode("span", _hoisted_9, "投票开始: " + _toDisplayString(_ctx.detail.detailContent.activity.startTime), 1), _createElementVNode("span", null, "投票截止: " + _toDisplayString(_ctx.detail.detailContent.activity.endTime), 1)])])]), _createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.rank, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "rankbox"
    }, [index == 0 ? (_openBlock(), _createElementBlock("img", _hoisted_11)) : index == 1 ? (_openBlock(), _createElementBlock("img", _hoisted_12)) : index == 2 ? (_openBlock(), _createElementBlock("img", _hoisted_13)) : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(index + 1), 1)), _createElementVNode("span", null, _toDisplayString(item.number) + "号", 1), _createElementVNode("span", _hoisted_15, _toDisplayString(item.name), 1), _createElementVNode("span", null, _toDisplayString(item.votes) + "票", 1)]);
  }), 128))])])])], 64);
}